import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ReimbursementForm from "./components/ReimbursementForm";
import useScript from "./hooks/useScript";
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();
  const cookieReportScript = `https://policy.cookiereports.com/0ecfe2e0_panel-${i18n.language}.js`;
  useScript(cookieReportScript);

  return (
    <div className="app">
      <Header />
      <main className="py-10">
        <div className="container">
          <div className="space-y-2 pb-2 border-b border-clr-purple">
            <h1 className="text-clr-purple">
              {t("h1")}
            </h1>
          </div>

          <div id="form" className="py-10">
            <ReimbursementForm />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
